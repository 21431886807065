.login-card {
  width: 400px;
  pointer-events: all;
}

.help-notice {
  width: 600px;
  text-align: center;
  pointer-events: all;
  margin-top: 12px;
}

@media only screen and (max-width: 700px) {
  .help-notice {
    width: 300px;
  }
}


@media only screen and (max-height: 525px) {
  .help-notice {
    display: none !important;
  }
}

@media only screen and (max-width: 700px) and (max-height: 650px) {
  .help-notice {
    display: none !important;
  }
}


.license-card {
  max-width: 700px;
}

.license-table {
  border-width: 0;
  margin-top: 16px;
}

.license-table td {
  padding-right: 20px;
}

.check-list {
  columns: 3;
  padding-bottom: 10px;
}
@media only screen and (max-width: 1000px) {
  .check-list {
    columns: 2;
  }
}
@media only screen and (max-width: 700px) {
  .check-list {
    columns: 1;
  }
}


.check-list li::marker {
  content: "✅︎";
  padding-inline-start: 1ch;
}

.check-list li {
  padding-left: 8px;
}

a {
  text-decoration: none !important;
}

.logo {
  width: 72px;
}

.content {
  padding: 10px;
  padding-top: 2em;
  padding-right: 20px;
  min-height: 100vh;
  font-family: "Mulish",Sans-serif;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  background-image: linear-gradient(110deg,#fff 65%,#4e7ae0 40%);
  background-size: 100% 610px;
  background-repeat: no-repeat;
}

.footer {
  background-color: #4e7ae0;
  margin: -10px;
  margin-right: -20px;
  margin-top: auto;
  color: #fff;
  text-align: center;
  padding: 18px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Mulish",Sans-serif;
}

.btn-success {
  background-color: #00b259 !important;
  border-color: #00b259 !important;
}

.btn-success:hover {
  color: #00b259 !important;
  background-color: #fff !important;
}

.topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.top-link {
  font-size: 18px;
  font-weight: 600;
  font-family: "Mulish",Sans-serif;
  color: #000;
  margin-left: 45px;
  white-space: nowrap;
}

.menu-button {
  background-color: #4e7ae0;
  border-radius: 6px;
}

.modal-body {
  text-align: center;
  font-size: 20px;
}

.arch-select {
  width: 140px !important;
}